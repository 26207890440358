const dataTags = [
  "Physique",
  "Chimie",
  "Biologie",
  "Géologie",
  "Astronomie",
  "Mathématique",
  "Informatique",
  "Neurosciences",
  "Génétique",
  "Ecologie",
  "Psychologie",
  "Sociologie",
  "Histoire",
  "Anthropologie",
  "Archéologie",
  "Economie",
  "Linguistique",
  "Philosophie",
  "Politique",
  "Climatologie",
  "Environnement",
  "Astrophysique",
  "Géographie",
  "Medecine",
  "Paléontologie",
  "Santé",
  "Zoologie",
];

export default dataTags;
