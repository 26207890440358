import React, { useEffect, useState } from "react";
import CardPrev from "../../components/Card/CardPrev";
import { getMembers } from "../../utils/axiosMembers";

const Preview = ({ memberData: initialMemberData }) => {
    const [memberData, setMemberData] = useState(initialMemberData);

    useEffect(() => {
        const fetchMemberData = async () => {
            if (!initialMemberData) {
                const userIdFromSession = sessionStorage.getItem("userId");
                if (!userIdFromSession) {
                    console.error("Aucun ID utilisateur trouvé dans la session.");
                    return;
                }

                try {
                    const membersData = await getMembers();
                    const foundMember = membersData.find(
                        (member) => member.userId === userIdFromSession
                    );

                    if (foundMember) {
                        setMemberData(foundMember);
                    } else {
                        console.warn("Aucun membre correspondant trouvé.");
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des membres :", error);
                }
            }
        };

        fetchMemberData();
    }, [initialMemberData]);

    if (!memberData) {
        return <p>Aucun membre trouvé.</p>;
    }

    return (
        <div className="dashboard-header">
            <h2>Prévisualisation de ta carte</h2>
            {/* Passez les données du membre à CardPrev */}
            <CardPrev member={memberData} />
        </div>
    );
};

export default Preview;