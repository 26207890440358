

function DashboardAdmin() {
    return (
        <div>
            <h1>Tableau de bord Admin</h1>
        </div>
    );
}

export default DashboardAdmin;
